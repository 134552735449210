import React, { useState } from "react"
import styled from "styled-components"
import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa"
import { AiFillInstagram } from "react-icons/ai"
import { IoLogoWhatsapp } from "react-icons/io"
import "@fontsource/encode-sans-expanded"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")

  const onSubmitForm = async e => {
    e.preventDefault()
    try {
      const body = { name, phone, email }
      const response = await fetch(
        "https://zajiyahcarnival.herokuapp.com/leads",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      )

      window.location = "/"
    } catch (err) {
      console.error(err.message)
    }
  }

  return (
    <FooterContainer id="footer">
      <FooterContent>
        <Main>
          <Address>
            <StaticImage
              quality={100}
              src="../../images/Icon.png"
              height={100}
              width={100}
              alt="Icon"
            />
            <h1 fontFamily="Cinzel">OFFICE</h1>
            <p>N-5, G.T Road, Wah Cantt, Pakistan.</p>
            <p>UAN: +92-304-1111-307</p>
            <p>
              Email: info@thecarnival.pk
              <br />
              <br />
              MON-SUN: 10AM - 7PM
              <br />
              FRI: 10AM - 1PM & 4PM - 7PM
            </p>
          </Address>
          <Contact id="contact">
            <h1>GET IN TOUCH</h1>
            <form onSubmit={onSubmitForm}>
              <FormWrap>
                <label htmlFor="name">
                  <input
                    type="text"
                    placeholder="Name"
                    id="name"
                    name="name"
                    required
                    value={name}
                    onChange={e => setName(e.target.value)}
                  ></input>
                </label>
                <Feild2>
                  <label htmlFor="phone">
                    <input
                      type="text"
                      placeholder="Phone"
                      id="phone"
                      inputMode="numeric"
                      name="phone"
                      required
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                    ></input>
                  </label>
                </Feild2>
                <Feild3>
                  <label htmlFor="email">
                    <input
                      type="email"
                      placeholder="Email"
                      id="email"
                      name="email"
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    ></input>
                  </label>
                </Feild3>
                <ButtonFour type="submit">Contact Me</ButtonFour>
              </FormWrap>
            </form>
          </Contact>
          <QuickLinks id="careers">
            <h1>Quick Links</h1>
            <LinkWrapper>
              <FooterLinkItems>
                <FooterLink to="/">Features</FooterLink>
                <FooterLink to="/">Pricing Plans</FooterLink>
                <FooterLink to="/">Company</FooterLink>
                <FooterLink to="/">Partners</FooterLink>
                <FooterLink to="/">Location</FooterLink>
                <FooterLink to="/">Updates</FooterLink>
                <FooterLink to="/">Blog</FooterLink>
              </FooterLinkItems>
              <FooterLinkItems2>
                <FooterLink to="/">FAQs</FooterLink>
                <FooterLink to="/">Help Desk</FooterLink>
                <FooterLink to="/">Careers</FooterLink>
              </FooterLinkItems2>
            </LinkWrapper>
          </QuickLinks>
        </Main>
        <Social>
          <a
            target="_blank"
            href="https://www.facebook.com/ZAJIYAHCARNIVAL"
            rel="noopener noreferrer"
          >
            <SocialIcon>
              <FaFacebook />
            </SocialIcon>
          </a>

          <a
            href="https://www.instagram.com/zajiyah_carnival/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon>
              <AiFillInstagram />
            </SocialIcon>
          </a>

          <a
            href="https://www.linkedin.com/in/zajiyah-carnival-b10052210/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon>
              <FaLinkedin />
            </SocialIcon>
          </a>

          <a
            href="https://twitter.com/ZajiyahC"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon>
              <FaTwitter />
            </SocialIcon>
          </a>

          <a
            href="https://www.instagram.com/zajiyah_carnival/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon>
              <FaYoutube />
            </SocialIcon>
          </a>

          <a
            href="https://api.whatsapp.com/send?phone=923070111307"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon>
              <IoLogoWhatsapp />
            </SocialIcon>
          </a>
        </Social>
        <WebsiteRights>
          CARNIVAL &copy; {new Date().getFullYear()} ZAJIYAH DEVELOPERS. ALL
          RIGHTS RESERVED.
        </WebsiteRights>
      </FooterContent>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.div`
  width: 100%;
  background: #1b2227;
  color: #fabf49;

  margin-top: 2rem;
`
const FooterContent = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 2.5rem;

  @media screen and (max-width: 960px) {
    grid-template-rows: 1fr;
  }
`
const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 2rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
    margin-left: 0;
  }


`
const Address = styled.div`
  text-align: left;
  margin-left: 2rem;

  h1 {
    font-family: "Cinzel", serif;
    font-weight: 300;
    font-size: 24px;
  }

  p {
    line-height: 1.5;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    margin: 2rem;
  }
  
`
const Contact = styled.div`
  h1 {
    font-family: "Cinzel", serif;
    font-weight: 300;
    font-size: 24px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  margin-left: -2.5rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`

const FormWrap = styled.div`
  input {
    padding: 1rem 1.5rem;
    width: 300px;
    height: 20px;
    outline: none;
    border-radius: 15px;
    justify-content: center;
    align-items: center;

    border: 1px solid #fabf49;
    background: #c8c8c8;
    margin: 1rem 1rem;


    @media screen and (max-width: 460px) {
    margin-bottom: 2rem;
    padding: 1rem 0.4rem;
  }


  }

  @media screen and (max-width: 960px) {
    margin-bottom: 2rem;
  }
  
`
const Feild2 = styled.div`
  margin-top: -1rem;
  margin-bottom: 1rem;
`

const Feild3 = styled.div`
  margin-top: -2rem;
  margin-bottom: 1rem;
`

const QuickLinks = styled.div`
  margin-left: 2rem;
  padding-left: 1rem;
  border-left: 1px solid #fabf49;

  h1 {
    font-family: "Cinzel", serif;
    font-weight: 300;
    font-size: 24px;
    text-align: left;
    margin-bottom: 1rem;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    border: none;
    margin-left: 0;
    padding-left: 0;
  }
`
const LinkWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 768px) {
    margin-left: 1rem;
    padding-left: 1rem;
  }

  @media screen and (max-width: 468px) {
    margin-left: 0rem;
    padding-left: 0rem;
  }
`

const FooterLinkItems = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (max-width: 768px) {
    margin-left: 1rem;
    padding-left: 1rem;
  }

  
`

const FooterLinkItems2 = styled.div`
  display: grid;
  margin-bottom: 6rem;
`
const FooterLink = styled.div`
  text-decoration: none;
  color: #fabf49;
  text-align: left;
  line-height: 1.5;

  &:hover {
    color: #fff;
  }
`

const Social = styled.div`
  font-size: 1.7rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2rem;

  a {
    text-decoration: none;
    color: #fabf49;
  }
`
const SocialIcon = styled.div`
  &:hover {
    color: #fff;
  }
`

const WebsiteRights = styled.small`
  color: #fabf49;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Cinzel", serif;
  font-size: 1rem;
  margin-top: -1.5rem;
`

const ButtonFour = styled.button`
  background: #e1ae44;
  white-space: nowrap;

  padding: 12px 26px;
  color: black;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);

  font-size: 13px;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  text-decoration: none;

  transition: 0.3s !important;
  border-radius: 15px;
  border: none;

  &:hover {
    background: white;
    color: #e1ae44;
    transform: translateY(-5px);
    box-shadow: 0px 8px 15px rgba(225, 174, 68, 0.2);
  }
`
